/* eslint-disable react/jsx-props-no-spreading */
import {
  DataGrid, GridOverlay,
  GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton,
  useGridApiContext, useGridRootProps,
} from '@mui/x-data-grid';
import {
  Box,
  FormControl, NativeSelect, Typography,
  Paper, LinearProgress, Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  minHeight: theme.spacing(8),
  display: 'flex',
  alignItems: 'center',
}));

const CustomLoadingOverlay = () => (
  <GridOverlay>
    <Box position="absolute" top={0} width="100%">
      <LinearProgress />
    </Box>
  </GridOverlay>
);

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const { state } = apiRef.current;
  const options = useGridRootProps();

  return (
    <Root>
      <Typography variant="body2">Page size:</Typography>
      <FormControl
        size="small"
        sx={{
          display: 'inline-flex',
          ml: 1,
          mr: 2,
          boxSizing: 'content-box',
        }}
      >
        <NativeSelect
          disableUnderline
          value={state.pagination.pageSize}
          onChange={(event) => (
            apiRef.current.setPageSize(parseInt(event.target.value, 10))
          )}
          sx={{
            '& .MuiNativeSelect-select': {
              fontSize: 'body2.fontSize',
            },
          }}
        >
          {options.rowsPerPageOptions.map((o) => (
            <option key={o} value={o}>{o}</option>
          ))}
        </NativeSelect>
      </FormControl>
      <Box marginLeft={1} marginRight={2}>
        <Typography variant="body2">
          {`Total rows: ${state.pagination.rowCount}`}
        </Typography>
      </Box>
      <Pagination
        showFirstButton
        showLastButton
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Root>
  );
};

const AdminDataGrid = (props) => (
  <Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
    <DataGrid
      pagination
      paginationMode="server"
      sortingOrder={['desc', 'asc']}
      filterMode="server"
      sortingMode="server"
      components={{
        LoadingOverlay: CustomLoadingOverlay,
        Toolbar: CustomToolbar,
        Pagination: CustomPagination,
      }}
      {...props}
    />
  </Paper>
);

export default AdminDataGrid;
