/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';

import PropTypes from 'prop-types';

import { IconButton, TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Check } from '@mui/icons-material';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingRight: 16,
  '& .MuiInputBase-input': {
    fontSize: 'smaller',
  },
});

const TextField = styled(MuiTextField)(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.5),
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 2,
  },
  '& .MuiInputBase-input': {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

const getCoordsValue = (lat, lon) => {
  if ((lat !== 0 && !lat) || (lon !== 0 && !lon)) {
    return null;
  }
  return {
    coordinates: {
      lat: Number(lat),
      lon: Number(lon),
    },
  };
};

const CoordinatesEditInputCell = ({
  id, value, api, field,
  onValidateLatitude = () => { }, onValidateLongitude = () => { },
}) => {
  const { coordinates: { lat, lon } = {} } = value || {};

  const [latitude, setLatitude] = useState(lat);
  const [longitude, setLongitude] = useState(lon);

  const handleSave = (event) => {
    if (!(onValidateLatitude(latitude).error)
      && !(onValidateLongitude(longitude).error)) {
      const val = getCoordsValue(latitude, longitude);

      api.setEditCellValue({ id, field, value: val }, event);
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    }
  };

  return (
    <Root>
      <TextField
        id="lat"
        label="Latitude"
        value={latitude ?? ''}
        onChange={(e) => setLatitude(e.target.value)}
        variant="outlined"
        size="small"
        title="Number with decimal point"
        {...onValidateLatitude(latitude)}
      />
      <TextField
        id="lon"
        label="Longitude"
        value={longitude ?? ''}
        onChange={(e) => setLongitude(e.target.value)}
        variant="outlined"
        size="small"
        title="Number with decimal point"
        {...onValidateLongitude(longitude)}
      />
      <IconButton
        color="secondary"
        size="small"
        title="Save"
        onClick={handleSave}
      >
        <Check />
      </IconButton>
    </Root>
  );
};

export default CoordinatesEditInputCell;

CoordinatesEditInputCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  api: PropTypes.any.isRequired,
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  onValidateLatitude: PropTypes.func,
  onValidateLongitude: PropTypes.func,
};
CoordinatesEditInputCell.defaultProps = {
  value: undefined,
  onValidateLatitude: undefined,
  onValidateLongitude: undefined,
};
