/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import {
  List, ListItem,
  ListItemText as MuiListItemText,
  ListItemSecondaryAction,
  Box, IconButton, Menu,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';
import { SpeciesType } from '@ibot/types';

const Root = styled('div')({
  minWidth: 190,
});

const ListItemText = styled(MuiListItemText)({
  minHeight: 28,
  display: 'flex',
  alignItems: 'center',
});

const ItemContent = ({ prefix, children, ...props }) => (
  <Box {...props}>
    {prefix ? `${prefix} ` : ''}
    {children}
  </Box>
);

const SubnomenclatoricList = ({
  data = [], nameComponent: NameComponent,
}) => {
  if (data.length === 0) {
    return null;
  }
  return (
    <List
      disablePadding
      dense
      sx={{
        mt: '-4px',
      }}
    >
      {
        data.map((d) => (
          <ListItem key={d.id} sx={{ py: 0 }}>
            <ListItemText
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 'smaller',
                },
              }}
              primary={(
                <ItemContent prefix="≡">
                  <NameComponent data={d} />
                </ItemContent>
              )}
            />
          </ListItem>
        ))
      }
    </List>
  );
};

const SynonymListItem = ({
  data,
  prefix,
  menuItems = [],
  nameComponent: NameComponent,
  showSubNomenclatoric = false,
  children,
}) => {
  const { synonym: subject } = data;
  const { 'synonyms-nomenclatoric-through': subnomenData } = subject;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <ListItemText
        primary={(
          <ItemContent prefix={prefix}>
            <NameComponent data={subject} />
          </ItemContent>
        )}
      />
      <ListItemSecondaryAction>
        {menuItems.length > 0 && (
          <>
            <IconButton
              aria-label="more-actions"
              aria-controls="more-actions-menu"
              aria-haspopup="true"
              size="small"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="more-actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              {menuItems.map((item, idx) => (
                // fix of the fwd ref warning
                // eslint-disable-next-line react/no-array-index-key
                <div key={idx}>
                  {item}
                </div>
              ))}
            </Menu>
          </>
        )}
      </ListItemSecondaryAction>
      {children}
      {
        showSubNomenclatoric && (
          <SubnomenclatoricList
            data={subnomenData}
            nameComponent={NameComponent}
          />
        )
      }
    </Root>
  );
};

export default SynonymListItem;

SynonymListItem.propTypes = {
  data: PropTypes.shape({
    synonym: SpeciesType.isRequired,
  }).isRequired,
  nameComponent: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.node),
  showSubNomenclatoric: PropTypes.bool,
  children: PropTypes.element,
};
SynonymListItem.defaultProps = {
  menuItems: undefined,
  showSubNomenclatoric: false,
  children: undefined,
};

ItemContent.propTypes = {
  prefix: PropTypes.string,
  children: PropTypes.element,
};
ItemContent.defaultProps = {
  prefix: undefined,
  children: undefined,
};
SubnomenclatoricList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    'synonyms-nomenclatoric-through': SpeciesType.type,
  })),
  nameComponent: PropTypes.func.isRequired,
};
SubnomenclatoricList.defaultProps = {
  data: [],
};
