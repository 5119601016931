/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@mui/material';

import PropTypes from 'prop-types';
import { SxType } from '@ibot/types';

const AdminTextField = ({
  readonly = false, sx = [], showWhen = true, hideWhen = false,
  ...textFieldProps
}) => {
  // hideWhen overrides showWhen
  if (!showWhen || hideWhen) {
    return null;
  }

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      margin="dense"
      disabled={readonly}
      sx={[
        readonly && {
          '& .MuiInputBase-root.Mui-disabled': {
            color: 'text.primary',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...textFieldProps}
    />
  );
};

export default AdminTextField;

AdminTextField.propTypes = {
  readonly: PropTypes.bool,
  className: PropTypes.string,
  showWhen: PropTypes.bool,
  hideWhen: PropTypes.bool,
  sx: SxType.type,
};
AdminTextField.defaultProps = {
  readonly: false,
  className: undefined,
  showWhen: true,
  hideWhen: false,
  sx: SxType.default,
};
