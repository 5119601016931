import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import trim from 'lodash.trim';
import pick from 'lodash.pick';

import config from '../config';

const {
  constants: { nonRegularWhitespacesRegex, escape },
} = config;

const nonRegularWhitespacesRegexObj = new RegExp(
  nonRegularWhitespacesRegex, 'gi',
);

function emptyToNull(obj) {
  if (!obj) {
    return obj;
  }
  return Object.keys(obj).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: obj[curr] === '' ? null : obj[curr],
    }),
    {},
  );
}

function replaceNonBreakingSpaces(value) {
  if (typeof value === 'string') {
    return value.replace(nonRegularWhitespacesRegex, '');
  }

  return Object.keys(value).reduce(
    (prev, curr) => {
      const val = value[curr];
      const newVal = typeof val === 'string'
        ? val.replace(nonRegularWhitespacesRegexObj, '') : val;
      return {
        ...prev,
        [curr]: newVal,
      };
    },
    {},
  );
}

function escapeDoubleQuotes(val) {
  if (typeof val !== 'string') {
    return val;
  }
  return val.replace(new RegExp(escape, 'gi'), '\\$&');
}

/**
 * if not string, return original value
 * else try JSON.parse
 * if fails, return original value.
 * @param {any} value
 */
function parseJSONSafe(value) {
  if (!value || (typeof value !== 'string')) {
    return value;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

/**
 * Creates a stringified version of the obj with picked properties
 * @param {object} val
 * @param {array<string>} properties properties to pick from obj
 */
function stringifyObj(val, properties = undefined) {
  if (!val) {
    return null;
  }
  if (typeof val === 'string') {
    return val;
  }
  let objToStringify = val;
  if (properties) {
    objToStringify = pick(val, properties);
  }
  return JSON.stringify(objToStringify);
}

/**
 * From data extract such keys and values, where keys start with str.
 * Keys in the new object are without str prefix.
 * @param {object} data
 * @param {string} str
 */
function getObjWKeysThatStartWithStr(data, str) {
  return (
    Object.keys(data)
      .filter((k) => k.indexOf(str) === 0)
      .reduce((newData, k) => {
        const newKey = k.replace(str, '');
        return {
          ...newData,
          [newKey]: data[k],
        };
      }, {})
  );
}

function curateNullableFields(data, keepFields, allNullableFields) {
  const fieldsToBeEmpty = allNullableFields.filter(
    (el) => !keepFields.includes(el),
  );
  return fieldsToBeEmpty.reduce((newData, k) => ({
    ...newData,
    [k]: null,
  }), data);
}

function stringifyValue(val) {
  if (val === undefined || val === null || typeof val === 'string') {
    return val;
  }
  return String(val);
}

export default {
  emptyToNull,
  replaceNonBreakingSpaces,
  escapeDoubleQuotes,
  parseJSONSafe,
  stringifyObj,
  stringifyValue,
  isEmpty,
  isEqual,
  trim,
  getObjWKeysThatStartWithStr,
  curateNullableFields,
};
