import {
  Drawer as MuiDrawer,
  IconButton, Divider, Toolbar,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const LeftDrawer = ({ open, onDrawerClose, children }) => (
  <Drawer
    variant="permanent"
    open={open}
  >
    <Toolbar
      color="inherit"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        py: 0,
        px: '8px',
      }}
    >
      <IconButton color="inherit" onClick={onDrawerClose} size="large">
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    {children}
  </Drawer>
);

export default LeftDrawer;

LeftDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

LeftDrawer.defaultProps = {
  children: undefined,
};
