import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const DividerSpaced = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'spacing',
})(({ theme, spacing = 1 }) => ({
  marginTop: theme.spacing(spacing),
  marginBottom: theme.spacing(spacing),
}));

export default DividerSpaced;
