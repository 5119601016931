import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

const CheckButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'checked',
})(({ theme, checked }) => ({
  ...(checked && {
    color: theme.palette.warning.light,
    borderColor: theme.palette.warning.light,
  }),
  ...(!checked && {
    color: theme.palette.success.light,
    borderColor: theme.palette.success.light,
  }),
}));

const AdminTimestampCheck = ({
  isChecked, checkedTimestamp, checkedBy, onCheck,
  editable = true,
}) => (
  <div>
    {
      isChecked && (
        <div>
          <div>
            Checked by:
            {' '}
            {checkedBy}
          </div>
          <div>
            Checked at:
            {' '}
            {new Date(Date.parse(checkedTimestamp)).toUTCString()}
          </div>
        </div>
      )
    }
    {editable && (
      <CheckButton
        checked={checkedTimestamp}
        size="small"
        variant="outlined"
        onClick={onCheck}
      >
        {checkedTimestamp ? 'Re-check' : 'Check'}
      </CheckButton>
    )}
  </div>
);

export default AdminTimestampCheck;

AdminTimestampCheck.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  checkedTimestamp: PropTypes.string,
  checkedBy: PropTypes.string,
  onCheck: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

AdminTimestampCheck.defaultProps = {
  checkedTimestamp: undefined,
  checkedBy: undefined,
  editable: true,
};
