import PropTypes from 'prop-types';

import { Box, Typography, Grid } from '@mui/material';

const LabelValueGrid = ({
  label,
  children = undefined,
  alignItems = 'center',
  alignContent = 'center',
}) => (
  <Box color="text.secondary">
    <Grid
      container
      alignContent={alignContent}
      alignItems={alignItems}
      spacing={2}
    >
      <Grid item sm={3} sx={{ textAlign: 'right' }}>
        <Typography
          component="span"
          variant="subtitle2"
          color="textSecondary"
        >
          {label}
          :
          {' '}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Typography
          component="span"
          variant="body2"
          color="textPrimary"
        >
          {children}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default LabelValueGrid;

LabelValueGrid.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  alignItems: PropTypes.string,
  alignContent: PropTypes.string,
};
LabelValueGrid.defaultProps = {
  children: undefined,
  alignItems: 'center',
  alignContent: 'center',
};
