import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  AppBar as MuiAppBar,
  Toolbar, IconButton, Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Search as SearchIcon,
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import config from 'config';
import LangMenu from './LangMenu';

const { routes } = config;

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const UpperMenu = ({ drawerOpen, onDrawerOpen }) => {
  const { t } = useTranslation();

  return (
    <AppBar position="absolute" open={drawerOpen}>
      <Toolbar sx={{ pr: '24px' }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerOpen}
          size="large"
          sx={{
            mr: 3,
            ...(drawerOpen && { display: 'none' }),
          }}
        >
          <SearchIcon />
        </IconButton>
        <Box display="flex" sx={{ flexGrow: 1 }}>
          <Typography
            component={RouterLink}
            variant="h6"
            color="inherit"
            noWrap
            to={routes.home.route}
            sx={{
              mr: 3,
              textDecoration: 'none',
            }}
          >
            SlovPlantList
          </Typography>
          <Button
            color="inherit"
            variant="text"
            component={RouterLink}
            to={routes.scientificNames.route}
            sx={{
              mr: 3,
            }}
          >
            {t('Scientific names')}
          </Button>
        </Box>
        <LangMenu />
      </Toolbar>
    </AppBar>
  );
};

export default UpperMenu;

UpperMenu.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  onDrawerOpen: PropTypes.func.isRequired,
};
