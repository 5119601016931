/* eslint-disable react/jsx-props-no-spreading */
import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

const BACKDROP_CLICK = 'backdropClick';

export const AdminEditDialog = ({ onEnter, onClose, ...props }) => (
  <Dialog
    fullWidth
    disableEscapeKeyDown
    scroll="paper"
    onClose={(e, reason) => (reason === BACKDROP_CLICK ? null : onClose(e))}
    TransitionProps={{
      onEnter,
    }}
    {...props}
  />
);

export const AdminEditDialogHigh = styled(AdminEditDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    height: `calc(100% - ${theme.spacing(10)})`,
  },
}));

AdminEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
};
AdminEditDialog.defaultProps = {
  onEnter: undefined,
};
