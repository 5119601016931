import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { matchPath } from 'react-router';

import {
  CssBaseline, Container,
  Box, Toolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

import UpperMenu from 'components/segments/Navigation/UpperMenu';
import config from 'config';

import LeftDrawer from './LeftDrawer';
import FilterRouter from './FilterRouter';
import Footer from './Footer';

const { routes } = config;

const isDrawerOpened = (route) => {
  const found = Object.keys(routes)
    .find((r) => {
      const match = matchPath(route, routes[r].route);
      return match && match.isExact === true;
    });

  if (!found) {
    return false;
  }
  return routes[found].drawerOpened;
};

const Main = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light'
    ? theme.palette.grey[100]
    : theme.palette.grey[900],
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
}));

const Base = ({ router: Router }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [searchValues, setSearchValues] = useState({});

  useEffect(() => {
    const isOpen = isDrawerOpened(pathname);
    setOpen(isOpen);
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSearch = (values, route) => {
    setSearchValues(values);
    history.push(route);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <UpperMenu drawerOpen={open} onDrawerOpen={handleDrawerOpen} />
      <LeftDrawer
        open={open}
        onDrawerClose={handleDrawerClose}
      >
        <FilterRouter
          pathname={pathname}
          closed={!open}
          onSearch={handleSearch}
        />
      </LeftDrawer>
      <Main component="main">
        <Toolbar />
        <Container
          maxWidth="lg"
          sx={{
            minHeight: 'calc(100vh - 100px)',
            py: 4,
            '& a:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <Router searchValues={searchValues} />
        </Container>
        <Footer />
      </Main>
    </Box>
  );
};

export default Base;

Base.propTypes = {
  router: PropTypes.func.isRequired,
};
