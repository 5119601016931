import { useMemo, forwardRef } from 'react';

import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ListItem, ListItemText, ListItemIcon,
} from '@mui/material';

const ListItemLink = ({
  icon, primary, to, sx,
}) => {
  const { isExact = false } = useRouteMatch({ path: to, strict: true }) || {};

  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <RouterLink to={to} ref={ref} {...itemProps} />
    )),
    [to],
  );

  return (
    <li>
      <ListItem
        selected={isExact}
        button
        component={renderLink}
        sx={sx}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

export default ListItemLink;

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};
ListItemLink.defaultProps = {
  icon: undefined,
  sx: undefined,
};
