import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Box, Container, Tabs, Tab, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { PageTitle } from '@ibot/components';
import { species as speciesUtils } from '@ibot/utils';

import { useTranslation } from 'react-i18next';

import {
  nomencatureService,
  genusService,
} from 'services';
import config from 'config';

import TabPanel from 'components/segments/Common/TabPanel';
import NameTitleSection from './Components/NameTitleSection';

import NameDetailOverview from './NameDetailOverview';
import NameDetailCategories from './NameDetailCategories';

const {
  status: statusConfig,
} = config;

const getStatusText = (ntype) => (
  statusConfig[ntype] ? statusConfig[ntype].i18nKey : ''
);

const Root = styled(Box)({
  '& a': {
    textDecoration: 'none',
    color: 'inherit',
  },
});

const NameDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  const [record, setRecord] = useState({});
  const [error, setError] = useState(undefined);

  const [synonymsNomenclatoric, setSynonymsNomenclatoric] = useState([]);
  const [synonymsTaxonomic, setSynonymsTaxonomic] = useState([]);
  const [synonymsOthers, setSynonymsOthers] = useState([]);
  const [invalidDesignations, setInvalidDesignations] = useState([]);
  const [misidentifications, setMisidentifications] = useState([]);

  const [familyAPG, setFamilyAPG] = useState();

  const [forRelations, setForRelations] = useState({});

  useEffect(() => {
    const fetch = async () => {
      try {
        const nomenRecord = await nomencatureService.getNomenclatureById(id);
        const { genusReference } = nomenRecord || {};

        const familyRecord = await genusService
          .getFamilyApgOfGenus(genusReference);

        const synonyms = await nomencatureService.getSynonymsOfId(id);
        const invalidDesigRecords = await nomencatureService
          .getInvalidDesignationsOfId(id);
        const misidentificationsRecords = await nomencatureService
          .getMisidentificationsOfId(id);

        const forRelationsRecords = await nomencatureService
          .getForRelationsOfId(id);

        setRecord(nomenRecord);
        setFamilyAPG(familyRecord);
        setSynonymsNomenclatoric(synonyms.nomenclatoricSynonyms);
        setSynonymsTaxonomic(synonyms.taxonomicSynonyms);
        setSynonymsOthers(synonyms.otherSynonyms);
        setInvalidDesignations(invalidDesigRecords);
        setMisidentifications(misidentificationsRecords);
        setForRelations(forRelationsRecords);
        setError(undefined);
      } catch (e) {
        if (e.response.status === 404) {
          setError(t('Name not found'));
        } else {
          setError(`${t('Error occured')}: ${e.message}`);
        }
      }
    };
    fetch();
  }, [id, t]);

  const {
    status,
    publication,
    acceptedNames,
    genusReference,
    parentCombination,
    taxonPosition,
    basionym,
    replaced,
    nomenNovum,
    nomenStatus,
    ...name
  } = record;

  return (
    <Root>
      <PageTitle
        websiteTitle="Slovplantlist"
        title={speciesUtils.listOfSpeciesString(name)}
      />
      {error ? (
        <Typography color="textSecondary" variant="h6">{error}</Typography>
      ) : (
        <>
          <NameTitleSection
            name={name}
            status={t(getStatusText(status))}
            publication={publication}
            genus={genusReference}
            familyAPG={familyAPG}
            vernacular={name.vernacular}
          />
          <Container maxWidth="md">
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newTab) => setActiveTab(newTab)}
              aria-label="Name details tabs"
              sx={{
                mb: 4,
              }}
            >
              <Tab label={t('tabs.category')} />
              <Tab label={t('tabs.overview')} />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <NameDetailCategories data={nomenStatus} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <NameDetailOverview
                status={status}
                acceptedNames={acceptedNames}
                synonyms={{
                  synonymsNomenclatoric,
                  synonymsTaxonomic,
                  synonymsOthers,
                }}
                invalidDesignations={invalidDesignations}
                misidentifications={misidentifications}
                relatives={{
                  parentCombination,
                  taxonPosition,
                  basionym,
                  nomenNovum,
                  replaced,
                }}
                forRelations={forRelations}
              />
            </TabPanel>
          </Container>
        </>
      )}
    </Root>
  );
};

export default NameDetail;
