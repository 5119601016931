/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import {
  SnackbarProvider, useSnackbar,
} from 'material-ui-snackbar-provider';

import PropTypes from 'prop-types';

import {
  Alert, Snackbar, Button,
} from '@mui/material';

const AlertSnackbar = ({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters,
}) => (
  <Snackbar
    autoHideDuration={3000}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...SnackbarProps}
  >
    <Alert
      severity={customParameters ? customParameters.type : undefined}
      action={action != null && (
        <Button color="inherit" size="small" {...ButtonProps}>
          {action}
        </Button>
      )}
      variant="filled"
    >
      {message}
    </Alert>
  </Snackbar>
);

export const NotificationsProvider = (props) => (
  <SnackbarProvider SnackbarComponent={AlertSnackbar} {...props} />
);

export function useNotifications() {
  const snackbar = useSnackbar();
  return useMemo(() => {
    const showMessage = (type) => (
      message,
      action,
      handleAction,
      customParameters,
    ) => (
      snackbar.showMessage(message, action, handleAction, {
        ...customParameters,
        type,
      })
    );
    return {
      ...snackbar,
      showMessage: showMessage('info'),
      showInfo: showMessage('info'),
      showWarning: showMessage('warning'),
      showError: showMessage('error'),
      showSuccess: showMessage('success'),
    };
  }, [snackbar]);
}

AlertSnackbar.propTypes = {
  message: PropTypes.string,
  action: PropTypes.string,
  ButtonProps: PropTypes.object,
  SnackbarProps: PropTypes.object,
  customParameters: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  }),
};
AlertSnackbar.defaultProps = {
  message: null,
  action: null,
  ButtonProps: {},
  SnackbarProps: {},
  customParameters: {},
};
