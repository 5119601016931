/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button } from '@mui/material';

import PropTypes from 'prop-types';

const AdminDeleteButton = ({
  onDelete, recordId, children, ...other
}) => {
  if (!recordId || !onDelete) {
    return null;
  }

  const handleClickWithAlert = () => {
    const result = window.confirm('Delete record permanently?');
    if (result) {
      onDelete();
    }
  };

  return (
    <Box flexGrow="1">
      <Button
        onClick={handleClickWithAlert}
        {...other}
        color="error"
      >
        {children || 'Delete'}
      </Button>
    </Box>
  );
};

export default AdminDeleteButton;

AdminDeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  recordId: PropTypes.string,
  children: PropTypes.node,
};

AdminDeleteButton.defaultProps = {
  recordId: undefined,
  children: undefined,
};
