/* eslint-disable react/forbid-prop-types */
import { generatePath, Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import { SxType } from '@ibot/types';

import {
  Box, List, ListItem, ListItemText, ListItemIcon,
} from '@mui/material';

import { LosName } from '@ibot/components';

import config from 'config';

const {
  synonymType,
  synonyms: synonymsConfig,
  routes,
} = config;

const syntypeToPrefix = (type) => {
  const typeKey = synonymType[type];
  return synonymsConfig[typeKey].prefix;
};

const ListItemSynonymPrefix = ({ type }) => (
  <ListItemIcon
    sx={{
      minWidth: 'auto',
      mr: '15px',
    }}
  >
    {syntypeToPrefix(type)}
  </ListItemIcon>
);

const SynonymListItemTemplate = ({
  sx,
  syntype,
  name,
  nameAdditions: Additions,
  neighborAdditions: NeighborAdditions,
}) => (
  <>
    <ListItem sx={sx} disableGutters>
      <Box>
        <ListItemSynonymPrefix type={syntype} />
        <LosName
          data={name}
          format="italic"
          component={RouterLink}
          to={generatePath(routes.nameDetail.route, { id: name.id })}
        />
      </Box>
      {Additions && <Additions />}
    </ListItem>
    {NeighborAdditions && <NeighborAdditions />}
  </>
);

export const SynonymListItemBasic = ({
  syntype, name,
}) => (
  <SynonymListItemTemplate syntype={syntype} name={name} />
);

export const SynonymListItemMisidentification = ({
  syntype, name, misidentificationAuthor,
}) => (
  <SynonymListItemTemplate
    syntype={syntype}
    name={name}
    nameAdditions={() => (
      <ListItemText
        secondary={`Misidentified by: ${misidentificationAuthor}`}
        sx={{
          display: 'block',
          pl: 3,
        }}
      />
    )}
    sx={{
      display: 'block',
    }}
  />
);

export const SynonymListItemTaxonomic = ({
  syntype, name, subsynonyms = [],
}) => (
  <SynonymListItemTemplate
    syntype={syntype}
    name={name}
    neighborAdditions={() => (
      <>
        {subsynonyms.length > 0 && (
          <List dense component="div" disablePadding>
            {subsynonyms.map((subsynonym) => (
              <SynonymListItemTemplate
                key={subsynonym.id}
                name={subsynonym}
                syntype={synonymsConfig.nomenclatoric.syntype}
                sx={{
                  pl: 3,
                  py: 0,
                }}
              />
            ))}
          </List>
        )}
      </>
    )}
  />
);

SynonymListItemTemplate.propTypes = {
  sx: SxType.type,
  syntype: PropTypes.number.isRequired,
  name: PropTypes.object,
  nameAdditions: PropTypes.func,
  neighborAdditions: PropTypes.func,
};

SynonymListItemTemplate.defaultProps = {
  sx: SxType.default,
  name: undefined,
  nameAdditions: undefined,
  neighborAdditions: undefined,
};

ListItemSynonymPrefix.propTypes = {
  type: PropTypes.number.isRequired,
};

SynonymListItemBasic.propTypes = {
  syntype: PropTypes.number.isRequired,
  name: PropTypes.object,
};
SynonymListItemBasic.defaultProps = {
  name: undefined,
};

SynonymListItemTaxonomic.propTypes = {
  syntype: PropTypes.number.isRequired,
  name: PropTypes.object,
  subsynonyms: PropTypes.arrayOf(PropTypes.object),
};
SynonymListItemTaxonomic.defaultProps = {
  name: undefined,
  subsynonyms: [],
};

SynonymListItemMisidentification.propTypes = {
  syntype: PropTypes.number.isRequired,
  name: PropTypes.object,
  misidentificationAuthor: PropTypes.string,
};
SynonymListItemMisidentification.defaultProps = {
  name: undefined,
  misidentificationAuthor: undefined,
};
