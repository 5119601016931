import {
  WhereBuilder, and, or,
  likep, like, regexp,
  neq, eq, gt, gte, lt, lte,
} from '../builders/where-builder';

import config from '../../config';

const { constants: { dataGrid: { operators } } } = config;

const dataGridResolveOperator = (operator, key, value) => {
  switch (operator) {
    case operators.contains:
      return likep(key, value);
    case operators.startsWith:
      return like(key, `${value}%`);
    case operators.endsWith:
      return like(key, `%${value}`);
    case operators.regexp:
      return regexp(key, value, { encodeUri: false });
    case operators.not:
    case operators.notEquals:
      return neq(key, value);
    case operators.after:
      return gt(key, value);
    case operators.onOrAfter:
      return gte(key, value);
    case operators.before:
      return lt(key, value);
    case operators.onOrBefore:
      return lte(key, value);
    case operators.is:
    case operators.equals:
    default:
      return eq(key, value);
  }
};

const dataGridResolveConjunction = (operator) => {
  switch (operator) {
    case 'or':
      return or;
    case 'and':
    default:
      return and;
  }
};

// --- PUBLIC --- //

function dataGridFilterItemsToWhereWB(items, linkOperator) {
  const wb = new WhereBuilder();

  if (!items || !linkOperator) {
    return wb;
  }

  const whereItems = items
    .filter(({ value }) => !!value)
    .map(({ columnField, operatorValue, value }) => (
      dataGridResolveOperator(
        operatorValue, columnField, value,
      )
    ));

  const conj = dataGridResolveConjunction(linkOperator);
  return wb.add(conj(...whereItems));
}

function dataGridFilterModelToWhereString(filterModel) {
  const { items = [], linkOperator } = filterModel || {};
  const wb = dataGridFilterItemsToWhereWB(items, linkOperator);
  return wb.buildString();
}

function makeWhereString(whereItems, op) {
  const conj = dataGridResolveConjunction(op);
  const wb = new WhereBuilder();
  return wb.add(conj(...whereItems)).buildString();
}

function processFilterModel(filterModel, filterManager) {
  const { linkOperator } = filterModel;
  const whereItems = filterManager.execute(filterModel);
  return makeWhereString(whereItems, linkOperator);
}

export default {
  dataGridResolveOperator,
  dataGridFilterItemsToWhereWB,
  dataGridFilterModelToWhereString,
  makeWhereString,
  processFilterModel,
};
