import Mustache from 'mustache';

import config from '../config';

const {
  constants: { mustacheTags },
} = config;

function parsePublication(publication) {
  if (!publication || !publication.displayType) {
    return undefined;
  }

  const {
    displayType, paperAuthor, paperTitle,
    seriesSource, volume, issue,
    publisher, editor, year, pages, journalName,
  } = publication;

  const typeMapping = config.mapping.displayType[displayType];
  const template = config.publication[typeMapping];

  return Mustache.render(template, {
    authors: paperAuthor,
    title: paperTitle,
    series: seriesSource,
    volume,
    issue: issue ? `(${issue})` : '',
    publisher,
    editor,
    year,
    pages,
    journal: journalName,
  }, {}, mustacheTags.publication);
}

function displayTypeToString(displayTypeInt) {
  return config.mapping.displayType[displayTypeInt];
}

function getDisplayTypes() {
  return config.mapping.displayType;
}

function publicationIdLabelFormatter(publication) {
  if (!publication) {
    return {};
  }
  return {
    id: publication.id,
    label: parsePublication(publication),
  };
}

export default {
  parsePublication,
  displayTypeToString,
  getDisplayTypes,
  publicationIdLabelFormatter,
};
