/* eslint-disable react/forbid-prop-types */
import {
  memo, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Paper, Popper, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight
    || element.scrollWidth > element.clientWidth
  );
}

const Root = styled('div')({
  alignItems: 'center',
  lineHeight: 24,
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  '& .cellValue': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const GridCellExpand = memo((props) => {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showFullCell]);

  return (
    <Root
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box ref={cellValue} className="cellValue">
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          sx={{ width, ml: '-17px' }}
        >
          <Paper
            elevation={1}
            sx={{
              minHeight: `${wrapper.current.offsetHeight - 3}px`,
              px: 2,
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{ p: '8px' }}
            >
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Root>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.any.isRequired,
  width: PropTypes.number.isRequired,
};

export default GridCellExpand;
