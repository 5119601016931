function personIdLabelFormatter(person) {
  if (!person) {
    return {};
  }

  const { id, persName } = person;

  return {
    id,
    label: persName,
  };
}

export default {
  personIdLabelFormatter,
};
