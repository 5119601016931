/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';
import {
  MenuItem, ListItemIcon,
} from '@mui/material';
import { Reply } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

export const GenericMenuItem = forwardRef((props, ref) => (
  <MenuItem ref={ref} {...props} />
));

const MakeIntoIcon = styled(Reply)({
  transform: 'scaleX(-1)',
});

export const ConvertMenuItem = ({ children, ...props }) => (
  <MenuItem {...props}>
    <ListItemIcon>
      <MakeIntoIcon />
    </ListItemIcon>
    {children}
  </MenuItem>
);

ConvertMenuItem.propTypes = {
  children: PropTypes.node,
};
ConvertMenuItem.defaultProps = {
  children: undefined,
};
