/* eslint-disable max-len */
export default {
  species: {
    name: {
      sl: 's.l.',
      tribus: 'tribus',
      hybrid: '×',
      infra: {
        subsp: 'subsp.',
        var: 'var.',
        subvar: 'subvar.',
        forma: 'forma',
        nothosubsp: 'nothosubsp.',
        nothoforma: 'nothoforma',
        proles: "'prol'",
        unranked: '[unranked]',
      },
      aggregates: {
        wrapL: '[',
        wrapR: ']',
      },
    },
    parts: [ // default basic parts of a species name
      'genus', 'species', 'subsp', 'var', 'subvar', 'forma', 'authors',
      'genusH', 'speciesH', 'subspH', 'subvarH', 'formaH', 'nothosubspH',
      'nothoformaH', 'authorsH',
    ],
  },
  publication: {
    paper: '{{authors}}{{#year}} ({{year}}){{/year}} {{title}}{{#journal}}. {{journal}}{{/journal}}{{#volume}}, {{volume}}{{/volume}}{{issue}}{{#pages}}:{{pages}}{{/pages}}',
    book: '{{authors}}{{#year}} ({{year}}){{/year}} {{title}}{{#publisher}}. {{publisher}}{{/publisher}}{{#pages}}. {{pages}}{{/pages}}',
    manuscript: '{{authors}}{{#year}} ({{year}}){{/year}} {{title}}{{#editor}}. In: (eds.) {{editor}}{{/editor}}{{#series}}, {{series}}{{/series}}{{#publisher}}. {{publisher}}{{/publisher}}{{#pages}}. {{pages}}{{/pages}}',
    chapter: '{{authors}}{{#year}} ({{year}}){{/year}} {{title}}{{#editor}}. In: (eds.) {{editor}}{{/editor}}{{#series}}, {{series}}{{/series}}{{#publisher}}. {{publisher}}{{/publisher}}{{#pages}}. {{pages}}{{/pages}}',
    report: '{{authors}}{{#year}} ({{year}}){{/year}} {{title}}{{#editor}}. In: (eds.) {{editor}}{{/editor}}{{#series}}, {{series}}{{/series}}{{#journal}}. {{journal}}{{/journal}}{{#volume}}, {{volume}}{{/volume}}{{issue}}{{#pages}}:{{pages}}{{/pages}}',
  },
  format: {
    italic: 'italic',
    plain: 'plain',
  },
  mapping: {
    displayType: {
      1: 'paper',
      2: 'book',
      3: 'manuscript',
      4: 'chapter',
      5: 'report',
    },
  },
  constants: {
    nonRegularWhitespacesRegex: '[\\u00A0\\u1680\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200A\\u202F\\u205F\\u3000]',
    escape: '"',
    dataGrid: {
      operators: {
        contains: 'contains',
        startsWith: 'startsWith',
        endsWith: 'endsWith',
        equals: 'equals',
        notEquals: 'notEquals',
        regexp: 'regexp',
        is: 'is',
        not: 'not',
        after: 'after',
        onOrAfter: 'onOrAfter',
        before: 'before',
        onOrBefore: 'onOrBefore',
      },
    },
    mustacheTags: {
      publication: ['{{', '}}'],
    },
  },
};
