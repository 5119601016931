import listUtils from './list';

const revisionComparator = (value, other) => (
  value.idData === other.idData
  && value.idStandardisedName === other.idStandardisedName
  && value.hDate === other.hDate
);

function submitRevisions(getRequest, putRequest, deleteRequest) {
  return async (
    idData,
    allNewRevisions,
    {
      getCurrentRevisionsUri,
      updateRevisionUri,
      deleteRevisionByIdUri,
    },
    accessToken,
  ) => {
    const getOriginals = async () => getRequest(
      getCurrentRevisionsUri, { id: idData }, accessToken,
    );

    const {
      idsToBeDeleted, toBeUpserted,
    } = await listUtils.manageList(
      getOriginals, allNewRevisions, revisionComparator,
    );
    const deletePromises = idsToBeDeleted.map((id) => (
      deleteRequest(deleteRevisionByIdUri, { id }, accessToken)
    ));
    const upsertPromises = toBeUpserted.map((revision) => (
      putRequest(
        updateRevisionUri, { ...revision, idData }, {}, accessToken,
      )
    ));

    const promises = [
      ...deletePromises,
      ...upsertPromises,
    ];
    return Promise.all(promises);
  };
}

export default {
  submitRevisions,
};
