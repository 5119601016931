/* eslint-disable react/jsx-props-no-spreading */
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import Base from './components/segments/Base/Base';
import Home from './components/pages/Home/Home';
import ScientificNames from './components/pages/ScientificNames/ScientificNames';
import SlovakNames from './components/pages/SlovakNames/SlovakNames';
import NameDetail from './components/pages/NameDetail/NameDetail';
import PageNotFound from './components/pages/NotFound/PageNotFound';

import config from './config';
import themeSetting from './config/theme';

const { routes } = config;

const theme = createTheme(themeSetting);

const Routing = (routingProps) => (
  <Switch>
    <Route exact path={routes.home.route}>
      <Home />
    </Route>
    <Route exact path={routes.scientificNames.route}>
      <ScientificNames {...routingProps} />
    </Route>
    <Route exact path={routes.slovakNames.route}>
      <SlovakNames />
    </Route>
    <Route path={routes.nameDetail.route}>
      <NameDetail />
    </Route>
    <Route>
      <PageNotFound />
    </Route>
  </Switch>
);

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Base router={Routing} />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
