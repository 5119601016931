/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Autocomplete,
  CircularProgress,
} from '@mui/material';

import AdminTextField from '../AdminTextField';

/**
 * Single select.
 * Options and value must be in format { id: "", label: ""}
 *
 * @param {*} param0
 * @returns
 */
const AdminAutocompleteAsync = ({
  loading = false, label, rows = 1, multiline = false, ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => (
        !(value.label) || option.label === value.label
      )}
      getOptionLabel={(option) => (
        (option && option.label) ? option.label : ''
      )}
      blurOnSelect
      selectOnFocus
      handleHomeEndKeys
      size="small"
      renderInput={(params) => (
        <AdminTextField
          {...params}
          rows={rows}
          multiline={multiline}
          label={label}
          helperText="Start typing and select a value from the list"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </div>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};

export default AdminAutocompleteAsync;

AdminAutocompleteAsync.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
};
AdminAutocompleteAsync.defaultProps = {
  loading: false,
  label: undefined,
  rows: 1,
  multiline: false,
};
