// const FILTER_NAME_ANY = '*';

function SortingManager(sorterNameGetter) {
  const handlers = {};

  return {
    addHandler: (key, h) => {
      handlers[key] = h;
    },
    execute: (sortModel = [], params = {}) => {
      const handlerKeys = Object.keys(handlers);
      const sortItemsUnhandled = sortModel.filter((s) => (
        !(handlers[sorterNameGetter(s)])
      ));

      const sortItemsHandled = handlerKeys
        .flatMap((key) => {
          const handler = handlers[key];
          // extract sortModel fields only relevant to the handler registered under the key
          const relevantFields = sortModel.filter((s) => (
            sorterNameGetter(s) === key
          ));

          // handler must return sortModel
          return handler(relevantFields, {
            ...params,
            filterKey: key,
          });
        })
        .filter((s) => !!s);

      return [
        ...sortItemsUnhandled,
        ...sortItemsHandled,
      ];
    },
  };
}

export default SortingManager;
