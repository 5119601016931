/* eslint-disable react/jsx-props-no-spreading */
import { Grid, Paper, Typography } from '@mui/material';

import PropTypes from 'prop-types';

const TitledSection = ({
  title, children, showWhen = true, hideWhen = false,
  ...paperProps
}) => {
  // hideWhen overrides showWhen
  if (!showWhen || hideWhen) {
    return null;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="overline" display="block">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          {...paperProps}
          sx={{
            minHeight: '20px',
            py: 2,
            px: 3,
            mb: '15px',
          }}
        >
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TitledSection;

TitledSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  showWhen: PropTypes.bool,
  hideWhen: PropTypes.bool,
};

TitledSection.defaultProps = {
  children: undefined,
  showWhen: true,
  hideWhen: false,
};
