/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';

import {
  Box,
  List, ListItem, ListItemIcon,
  CircularProgress, Divider, IconButton,
} from '@mui/material';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Done as DoneIcon,
  Delete as RemoveIcon,
} from '@mui/icons-material';

import PropTypes from 'prop-types';

import { hooks } from '@ibot/core';

import AdminAutocompleteAsync from './Autocomplete/AdminAutocompleteAsync';
import AdminTextField from './AdminTextField';

const AdminAddableList = ({
  id,
  data = [],
  // options: propsOptions = [],
  onSearch,
  onAddItemToList,
  onRowDelete,
  getRowId,
  itemComponent: ListRowItem,
  accessToken = '', // pass when onSearch function requires it
  optionsLabelKey,
  renderMenu,
  ...itemSpecificProps
}) => {
  const [inputOpen, setInputOpen] = useState(false);

  const {
    selected,
    options,
    loading,
    handleChange,
    handleFetch,
  } = hooks.useAsyncAutocomplete(onSearch, accessToken);

  const handleAddItem = () => {
    if (selected && selected.id) {
      onAddItemToList(selected);
      handleChange();
    }
    setInputOpen(false);
  };
  const handleCancelItem = () => (
    setInputOpen(false)
  );

  return (
    <div>
      <List disablePadding dense>
        {
          data.map((d, index) => {
            const rowId = getRowId ? getRowId(d) : index;
            return (
              <ListItem
                key={rowId}
                divider={index < data.length - 1}
                sx={{
                  py: 0,
                  px: 1,
                  alignItems: 'center',
                }}
              >
                <ListItemIcon>
                  <IconButton
                    edge="start"
                    size="small"
                    onClick={() => onRowDelete(rowId)}
                    title="Remove item"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: '28px',
                      m: 0.5,
                    }}
                  />
                </ListItemIcon>
                <ListRowItem
                  rowId={rowId}
                  data={d}
                  accessToken={accessToken}
                  {...itemSpecificProps}
                />
              </ListItem>
            );
          })
        }
      </List>
      {
        inputOpen ? (
          <Box display="flex" alignItems="flex-start" pl={1}>
            <IconButton
              size="small"
              color="default"
              onClick={handleCancelItem}
              title="Cancel"
              sx={{ mt: 2 }}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              disabled={!selected || !selected.id}
              onClick={handleAddItem}
              title="Confirm add"
              sx={{ mt: 2 }}
            >
              <DoneIcon />
            </IconButton>
            <Box flexGrow={1}>
              <AdminAutocompleteAsync
                id={id}
                options={options}
                value={selected}
                loading={loading}
                onChange={handleChange}
                onInputChange={handleFetch}
                renderInput={(params) => (
                  <AdminTextField
                    {...params}
                    autoFocus
                    label="Add to list"
                    helperText="Start typing and select a value from the list"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <div>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </div>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        ) : (
          <div>
            {
              data && data.length > 0 && (
                <Divider />
              )
            }
            <Box pl={1}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => setInputOpen(true)}
                title="Add item"
              >
                <AddIcon />
              </IconButton>
            </Box>
          </div>
        )
      }
    </div>
  );
};

export default AdminAddableList;

AdminAddableList.propTypes = {
  id: PropTypes.string,
  optionsLabelKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  // options: PropTypes.arrayOf(PropTypes.object),
  itemComponent: PropTypes.func.isRequired,
  renderMenu: PropTypes.func,
  getRowId: PropTypes.func,
  onAddItemToList: PropTypes.func.isRequired,
  onRowDelete: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  async: PropTypes.bool,
  accessToken: PropTypes.string,
};

AdminAddableList.defaultProps = {
  id: undefined,
  optionsLabelKey: 'label',
  data: [],
  // options: undefined,
  async: false,
  onSearch: undefined,
  renderMenu: undefined,
  getRowId: undefined,
  accessToken: '',
};
