import { useState } from 'react';

import {
  Box, Typography, Toolbar, Divider,
  List, ListItem, IconButton,
  ClickAwayListener, Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  Delete as DeleteIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import config from 'config';

import ListItemCollapsible from './Components/ListItemCollapsible';
import ListItemCheckbox from './Components/ListItemCheckbox';

const { status } = config;

const statusOptions = [status.A, status.S, status.PC, status.TP]
  .map(({ key, i18nKey }) => ({
    key,
    value: i18nKey,
  }));

const ToolbarButtons = styled(Box)({
  marginLeft: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
});

const WarningMessage = styled(Box)((theme) => ({
  color: theme.palette.warning.main,
}));

const ValidationMessage = ({ open, onClose }) => {
  if (!open) {
    return null;
  }
  return (
    <ClickAwayListener onClickAway={onClose}>
      <WarningMessage
        variant="body2"
        component="span"
      >
        <Trans i18nKey="tooltips.emptyWarning">
          Please provide a value
          <br />
          or check a Status below
        </Trans>
      </WarningMessage>
    </ClickAwayListener>
  );
};

const FilterTemplate = ({
  closed, onSearch, onReset, onValidate, children,
}) => {
  const { t } = useTranslation();

  const [checkedStatus, setCheckedStatus] = useState([]);
  const [validationMessageOpen, setValidationMessageOpen] = useState(false);

  const handleCheckStatus = (value) => {
    const currentIndex = checkedStatus.indexOf(value);
    const newChecked = [...checkedStatus];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedStatus(newChecked);
  };

  const handleValidate = () => {
    if (!onValidate) {
      return true;
    }
    if (!onValidate()) {
      return !!(checkedStatus.length); // if children fields are invalid, then at least one status must be checked
    }
    return true;
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (handleValidate()) {
      onSearch({
        status: checkedStatus,
      });
    } else {
      setValidationMessageOpen(true);
    }
  };

  const handleReset = () => {
    onReset();
    setCheckedStatus([]);
  };

  if (closed) {
    return (
      <Toolbar variant="dense" />
    );
  }

  return (
    <Box>
      <form noValidate autoComplete="off">
        <List>
          <ListItem>
            <Typography variant="h6" color="secondary">
              {t('Search')}
            </Typography>
            <ToolbarButtons>
              <Tooltip
                title={t('tooltips.clear')}
              >
                <IconButton
                  color="secondary"
                  edge="end"
                  variant="outlined"
                  onClick={handleReset}
                  aria-label="Clear search fields"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ToolbarButtons>
          </ListItem>
          {children}
          <ListItem dense>
            <ToolbarButtons>
              <IconButton
                type="submit"
                color="secondary"
                edge="end"
                variant="outlined"
                onClick={handleSearch}
                aria-label="Submit search"
                size="large"
              >
                <SearchIcon />
              </IconButton>
              <Box>
                <ValidationMessage
                  open={validationMessageOpen}
                  onClose={() => setValidationMessageOpen(false)}
                />
              </Box>
            </ToolbarButtons>
          </ListItem>
        </List>
        <List>
          <Divider />
          <ListItemCollapsible label={t('nameStatus')}>
            {statusOptions.map(({ key, value }) => (
              <ListItemCheckbox
                id={key}
                key={key}
                label={t(value)}
                checked={checkedStatus.includes(key)}
                onClick={handleCheckStatus}
              />
            ))}
          </ListItemCollapsible>
          <Divider />
        </List>
      </form>
    </Box>
  );
};

export default FilterTemplate;

FilterTemplate.propTypes = {
  closed: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  children: PropTypes.node,
};

FilterTemplate.defaultProps = {
  onValidate: undefined,
  children: undefined,
};

ValidationMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
