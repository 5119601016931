/* eslint-disable import/prefer-default-export */
import { useState } from 'react';

import { miscUtils } from '@ibot/utils';

export function useModal({
  initialEditId = undefined,
  history = undefined,
  historyPath = '',
} = {}) {
  const [showModal, setShowModal] = useState(!!initialEditId);
  const [editId, setEditId] = useState(initialEditId);

  const handleShowModal = (id) => {
    setEditId(id);
    if (history) {
      const hp = `${historyPath}/${id}`;
      history.push(hp);
    }
    setShowModal(true);
  };

  const handleHideModal = () => setShowModal(false);

  return {
    showModal,
    editId: miscUtils.stringifyValue(editId),
    handleShowModal,
    handleHideModal,
  };
}
