import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h3">404 - Not Found!</Typography>
      <Link to="/">{t('Return home')}</Link>
    </div>
  );
};

export default PageNotFound;
