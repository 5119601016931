export default {
  palette: {
    primary: {
      main: '#33691e',
    },
    secondary: {
      main: '#c2185b',
    },
  },
};
