import differenceWith from 'lodash.differencewith';
import intersectionWith from 'lodash.intersectionwith';

/**
 * Items that:
 *  are in currentList but are not in newList.
 * Compare by comparator
 * @param {array} currentList
 * @param {array} newList
 * @returns {array}
 */
const getItemsToBeDeleted = (currentList, newList, comparator) => (
  differenceWith(currentList, newList, comparator)
);

/**
 * Upsert items:
 *  - that are in currentList and are in newList
 *  - use id from currentList (an item can be removed and then added to the list -> it does not have id anymore)
 *  - everything else from newList (e.g. rorder, syntype might have changed)
 * Insert synonyms:
 *  - that are not in currentList and are in newList
 *  - they do not have id
 * Compare by idParent and idPynonym.
 * @param {array} currentList
 * @param {array} newList
 * @param {number} syntype
 * @param {string} accessToken
 */
const getItemsToUpsert = (
  currentList, newList, comparator,
) => {
  // in newList that are not in currentList
  const toCreate = differenceWith(newList, currentList, comparator);
  const toUpdate = intersectionWith(currentList, newList, comparator) // find items that are in both arrays
    .map((cItem) => { // find those items in newList and use everything except id
      const newItem = newList.find((l) => comparator(cItem, l));
      return {
        ...newItem,
        id: cItem.id,
      };
    });

  return [...toCreate, ...toUpdate];
};

async function manageList(getOriginals, allNewList, comparator) {
  const originalList = await getOriginals();

  const toBeDeleted = getItemsToBeDeleted(
    originalList, allNewList, comparator,
  );
  const idsToBeDeleted = toBeDeleted.map(({ id }) => id);
  const toBeUpserted = getItemsToUpsert(originalList, allNewList);

  return {
    toBeDeleted,
    idsToBeDeleted,
    toBeUpserted,
  };
}

export default {
  manageList,
};
