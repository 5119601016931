import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';

import { italic, plain } from './common';
import config from '../config';

const {
  species: {
    name: configName,
    parts: configNameParts,
  },
} = config;
const {
  aggregates: { wrapL, wrapR },
} = configName;

const makeSl = (string) => {
  const { sl } = configName;
  if (string && string.includes(sl)) {
    const modString = string.replace(sl, '');
    return { s: modString, hasSl: true };
  }
  return { s: string, hasSl: false };
};

/*
    For every property in config.species.name.infra

    Names of the infra taxa must match the ones of the listOfSpecies table columns.
    Notho- are not used.
*/
const infraTaxa = (nomenclature) => {
  const infs = [];

  const configInfraTaxa = configName.infra;

  Object.keys(configInfraTaxa).forEach((infra) => {
    const infraValue = nomenclature[infra];

    if (infraValue) {
      const infraLabel = configInfraTaxa[infra];
      infs.push(plain(infraLabel));
      infs.push(italic(infraValue));
    }
  });

  return infs;
};

const invalidDesignation = (name, syntype) => {
  if (syntype === '1') {
    let newname = [];
    newname.push(plain('"'));
    newname = newname.concat(name);
    newname.push(plain('"'));
    return newname;
  }
  return name;
};

function listOfSpeciesFormat(nomenclature, options = {}) {
  const opts = {
    isAuthors: true,
    isPublication: false,
    isTribus: false,
    isAggregates: false,
    ...options,
  };
  const {
    species, genus,
    subsp, var: varieta, forma,
    authors, publication, tribus,
    aggregate, subaggregate,
  } = nomenclature;

  let isAuthorLast = true;

  let name = [];
  const slResult = makeSl(species);

  if (genus) {
    name.push(italic(genus));
  }
  name.push(italic(slResult.s));

  if (slResult.hasSl) {
    name.push(plain(configName.sl));
  }

  const infras = infraTaxa(nomenclature);

  if (species === subsp || species === varieta || species === forma) {
    if (opts.isAuthors && authors) {
      name.push(plain(authors));
    }
    isAuthorLast = false;
  }

  name = name.concat(infras);

  if (opts.isAuthors && isAuthorLast && authors) {
    name.push(plain(authors));
  }

  const {
    genusH, speciesH, subspH, varH, subvarH, formaH,
    nothosubspH, nothoformaH, authorsH,
  } = nomenclature;
  if (
    genusH || speciesH || subspH || varH || subvarH || formaH
    || nothosubspH || nothoformaH || authorsH
  ) {
    const h = {
      genus: genusH,
      species: speciesH,
      subsp: subspH,
      var: varH,
      subvar: subvarH,
      forma: formaH,
      nothosubsp: nothosubspH,
      nothoforma: nothoformaH,
      authors: authorsH,
    };
    name.push(plain(configName.hybrid));
    name = name.concat(listOfSpeciesFormat(h));
  }

  name = invalidDesignation(name, options.syntype);

  if (opts.isPublication && publication) {
    name.push(plain(','));
    name.push(plain(publication));
  }
  const aggregates = [aggregate, subaggregate].filter((a) => !!a);
  if (opts.isAggregates && aggregates.length > 0) {
    const aggregatesString = `${wrapL}${aggregates.join(', ')}${wrapR}`;
    name.push(plain(aggregatesString));
  }
  if (opts.isTribus && tribus) {
    name.push(plain(tribus));
  }
  return name;
}

function listOfSpeciesString(name, options) {
  const nameArr = listOfSpeciesFormat(name, options);
  return nameArr.map(({ string }) => string).join(' ');
}

function listOfSpeciesIdLabelFormatter(name) {
  if (!name) {
    return {};
  }
  return {
    id: name.id,
    label: listOfSpeciesString(name),
  };
}

/**
 * Creates equality
 * @param {object} a
 * @param {object} b
 * @param {array} properties overrides default properties
 */
function areEqualSpecies(a, b, properties = undefined) {
  const keys = properties || configNameParts;
  const aPicked = pick(a, keys);
  const bPicked = pick(b, keys);
  return isEqual(aPicked, bPicked);
}

function sorterLex(losA, losB) {
  // a > b = 1
  if (losA.genus > losB.genus) {
    return 1;
  }
  if (losA.genus < losB.genus) {
    return -1;
  }
  if (losA.species > losB.species) {
    return 1;
  }
  if (losA.species < losB.species) {
    return -1;
  }
  if (losA.subsp > losB.subsp) {
    return 1;
  }
  if (losA.subsp < losB.subsp) {
    return -1;
  }
  if (losA.var > losB.var) {
    return 1;
  }
  if (losA.var < losB.var) {
    return -1;
  }
  if (losA.forma > losB.forma) {
    return 1;
  }
  if (losA.forma < losB.forma) {
    return -1;
  }
  if (losA.subvar > losB.subvar) {
    return 1;
  }
  if (losA.subvar < losB.subvar) {
    return -1;
  }
  if (losA.authors > losB.authors) {
    return 1;
  }
  if (losA.authors < losB.authors) {
    return -1;
  }
  // hybrid fields next
  if (losA.genusH > losB.genusH) {
    return 1;
  }
  if (losA.genusH < losB.genusH) {
    return -1;
  }
  if (losA.speciesH > losB.speciesH) {
    return 1;
  }
  if (losA.speciesH < losB.speciesH) {
    return -1;
  }
  if (losA.subspH > losB.subspH) {
    return 1;
  }
  if (losA.subspH < losB.subspH) {
    return -1;
  }
  if (losA.varH > losB.varH) {
    return 1;
  }
  if (losA.varH < losB.varH) {
    return -1;
  }
  if (losA.formaH > losB.formaH) {
    return 1;
  }
  if (losA.formaH < losB.formaH) {
    return -1;
  }
  if (losA.subvarH > losB.subvarH) {
    return 1;
  }
  if (losA.subvarH < losB.subvarH) {
    return -1;
  }
  if (losA.authorsH > losB.authorsH) {
    return 1;
  }
  if (losA.authorsH < losB.authorsH) {
    return -1;
  }
  return 0;
}

function synonymSorterLex(synA, synB) {
  return sorterLex(synA.synonym, synB.synonym);
}

export default {
  listOfSpeciesFormat,
  listOfSpeciesString,
  listOfSpeciesIdLabelFormatter,
  areEqualSpecies,
  sorterLex,
  synonymSorterLex,
};
