/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, IconButton,
} from '@mui/material';
import {
  Add as AddIcon,
} from '@mui/icons-material';

import PropTypes from 'prop-types';

import AdminAutocompleteAsync from './AdminAutocompleteAsync';

const DefaultBtn = ({ onClick, label = 'Add new', ...props }) => (
  <IconButton
    color="primary"
    aria-label={label}
    title={label}
    onClick={onClick}
    {...props}
    size="large"
  >
    <AddIcon />
  </IconButton>
);

const AdminAutocompleteWithButton = ({
  onBtnClick,
  btnLabel,
  btnComponent: Btn = DefaultBtn,
  loading = false, label, rows = 1, multiline = false,
  ...props
}) => (
  <Box display="flex">
    <Box flexGrow={1}>
      <AdminAutocompleteAsync
        loading={loading}
        label={label}
        rows={rows}
        multiline={multiline}
        {...props}
      />
    </Box>
    <Box mt={0.5}>
      <Btn label={btnLabel} onClick={onBtnClick} />
    </Box>
  </Box>
);

export default AdminAutocompleteWithButton;

AdminAutocompleteWithButton.propTypes = {
  onBtnClick: PropTypes.func.isRequired,
  btnLabel: PropTypes.string,
  btnComponent: PropTypes.func,
  loading: PropTypes.bool,
  label: PropTypes.string,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
};
AdminAutocompleteWithButton.defaultProps = {
  btnLabel: undefined,
  btnComponent: undefined,
  loading: false,
  label: undefined,
  rows: 1,
  multiline: false,
};
DefaultBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};
DefaultBtn.defaultProps = {
  label: undefined,
};
