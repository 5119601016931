/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { SxType } from '@ibot/types';

import {
  List, ListItem,
} from '@mui/material';

const NameList = ({ list = [], sx = [] }) => (
  <List sx={sx} dense disablePadding>
    {list.map(({ id, name }) => (
      <ListItem key={id} disableGutters>
        {name}
      </ListItem>
    ))}
  </List>
);

export default NameList;

NameList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({ // TODO: change to los name
    id: PropTypes.number.isRequired,
    name: PropTypes.any.isRequired,
  })),
  sx: SxType.type,
};

NameList.defaultProps = {
  list: [],
  sx: SxType.default,
};
