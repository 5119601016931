/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import {
  Box, Breadcrumbs, Grid, Paper, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { GenusName, LosName } from '@ibot/components';

import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import LabelValue from 'components/segments/Common/LabelValue';

const HigherTaxaPaper = styled((props) => (
  <Paper
    {...props}
    elevation={0}
    variant="outlined"
  />
))(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
}));

const HigherTaxa = ({ genus, familyAPG }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs
      component="div"
      separator=">"
      aria-label="higher taxa"
    >
      <LabelValue label={t('Family')}>
        {familyAPG ? familyAPG.name : '-'}
      </LabelValue>
      <LabelValue label={t('Genus')}>
        <GenusName data={genus} />
      </LabelValue>
    </Breadcrumbs>
  );
};

const NameTitleSection = ({
  name, status, publication = '-', genus, familyAPG, vernacular,
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        mt: '-32px',
      }}
    >
      <Grid item xs={12}>
        <Paper
          square
          elevation={0}
          variant="outlined"
          sx={{
            pt: '40px',
            pb: 2,
            px: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant="button" display="block" gutterBottom>
            {status}
          </Typography>
          <Typography variant="h4" component="h1" display="block" gutterBottom>
            <LosName data={name} format="italic" />
          </Typography>
          <Typography variant="body2" display="block" gutterBottom>
            <Box color="text.secondary" component="span">
              {t('Published in')}
              :
            </Box>
            {' '}
            {publication || '-'}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <HigherTaxaPaper>
          <HigherTaxa genus={genus} familyAPG={familyAPG} />
        </HigherTaxaPaper>
      </Grid>
      <Grid item xs={12}>
        <HigherTaxaPaper>
          <LabelValue label={t('Vernacular')}>{vernacular || '-'}</LabelValue>
        </HigherTaxaPaper>
      </Grid>
    </Grid>
  );
};

export default NameTitleSection;

NameTitleSection.propTypes = {
  name: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  publication: PropTypes.string,
  genus: PropTypes.object,
  familyAPG: PropTypes.object,
  vernacular: PropTypes.string,
};

NameTitleSection.defaultProps = {
  publication: '-',
  genus: undefined,
  familyAPG: undefined,
  vernacular: '-',
};

HigherTaxa.propTypes = {
  genus: PropTypes.object,
  familyAPG: PropTypes.object,
};
HigherTaxa.defaultProps = {
  genus: undefined,
  familyAPG: undefined,
};
