/* eslint-disable react/jsx-props-no-spreading */
import { Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

const AdminToolbar = styled((props) => (
  <Toolbar {...props} disableGutters />
))(({ theme }) => ({
  columnGap: theme.spacing(2),
}));

export default AdminToolbar;
