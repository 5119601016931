// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

export default {
  type: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  default: [],
};
