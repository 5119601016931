/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import {
  DialogTitle, IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import PropTypes from 'prop-types';

const AdminDialogTitle = ({
  children, onClose, ...other
}) => (
  <DialogTitle
    sx={{
      m: 0,
      p: 2,
    }}
    {...other}
  >
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        title="Close this window"
        onClick={onClose}
        size="large"
        sx={{
          position: 'absolute',
          right: 1,
          top: 1,
          color: 'grey[500]',
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
);

export default AdminDialogTitle;

AdminDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

AdminDialogTitle.defaultProps = {
  children: undefined,
};
